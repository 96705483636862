import styled, { css } from 'styled-components';
import brand from './brand';
import { lighten, transparentize } from 'polished';
export const ThemeWrapper = styled.div `
  display: contents;
`;
/**
 * Injects the theme switching for Groundforce / TPA.
 * Make sure to not include this on a pseudoelement, as they can't query parents for some reason.
 */
export const ThemeSwitch = (options) => css `
  --theme: ${options.groundforceColour ?? brand.primary.base};
  --theme-hover: ${options.groundforceColourHover ?? lighten(0.1, brand.primary.base)};

  ${options.generateOpacity &&
    `--theme-half-opacity: ${transparentize(0.5, options.groundforceColour ?? brand.primary.base)};`}

  ${options.groundforcePseudoColourAlt && `--theme-alt: ${options.groundforcePseudoColourAlt};`}

  [data-theme='tpa'] &,
  &[data-theme='tpa'] {
    --theme: ${options.tpaColour ?? brand.tpa.base};
    --theme-hover: ${options.tpaColourHover ?? lighten(0.1, brand.tpa.base)};

    ${options.generateOpacity &&
    `--theme-half-opacity: ${transparentize(0.5, options.tpaColour ?? brand.tpa.base)};`}

    ${options.tpaPseudoColourAlt && `--theme-alt: ${options.tpaPseudoColourAlt};`}
  }
`;
